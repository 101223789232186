import { useMemo } from 'react'

const tg = window.Telegram.WebApp

const AdminIds = [
	445068709,
	172711705,
	394476541,
	583509739
]

export function useTelegram() {
	const {user, isAdmin} = useMemo(() => ({user: tg?.initDataUnsafe?.user, isAdmin: AdminIds.includes(tg?.initDataUnsafe?.user?.id)}),
		[])

	// const isAdmin = true
	// const user = {id: 445068709}

	return {
		tg,
		user,
		isAdmin,
	}
}